import "./form4.css";

import React, { useRef } from "react";

import { Footer } from "../../component/footer/footer";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

const Form4 = () => {
  const form = useRef();
  let navigate = useNavigate();

  const sendEmail = async (e) => {
    e.preventDefault();

    // Function to read a file as a Data URL
    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    // Array to hold file reading promises
    const fileReadPromises = [
      readFile(e.target[8].files[0]),
      readFile(e.target[9].files[0]),
      readFile(e.target[10].files[0]),
      readFile(e.target[11].files[0]),
    ];

    try {
      // Wait for all file reads to complete
      const [attachment, attachment1, attachment2, attachment3] =
        await Promise.all(fileReadPromises);

      const templateParams = {
        form_type: e.target[0].value,
        user_name: e.target[1].value,
        no_handphone: e.target[2].value,
        user_email: e.target[3].value,
        address: e.target[4].value,
        nama_bencana: e.target[5].value,
        waktu_kejadian: e.target[6].value,
        lokasi: e.target[7].value,
        attachment,
        attachment1,
        attachment2,
        attachment3,
      };

      // Send email using emailjs
      await emailjs.send(
        "service_qlapca8",
        "template_7lbdctt",
        templateParams,
        "Th0vGbLxyxseyaRfl"
      );

      navigate("/successPage");
    } catch (error) {
      console.log(error.text || error);
    }
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
        <div className="form-wrapper" id="form">
          <div className="form-wrapper-title">
            <div className="form-wrapper-title-main">FORMULIR LAPOR MANG</div>
            <div className="form-wrapper-title-main-label">
              Silahkan masukan data anda disini
            </div>
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Tujuan Pelaporan</label>
            <input
              className="form-input"
              type="text"
              name="form_type"
              value="Bencana"
              disabled
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Nama Lengkap</label>
            <input
              className="form-input"
              type="text"
              name="user_name"
              required
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">No Handphone</label>
            <input
              className="form-input"
              type="number"
              name="no_handphone"
              required
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Email</label>
            <input
              className="form-input"
              type="email"
              name="user_email"
              required
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Judul Laporan</label>
            <input className="form-input" type="text" name="address" required />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Deskripsi Laporan</label>
            <textarea
              className="form-input"
              type="text"
              name="nama_bencana"
              rows="4"
              cols="50"
              required
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">Tanggal Kejadian</label>
            <div
              style={{
                fontSize: 9,
              }}
            >
              *Hari/Bulan/Tahun/Jam
            </div>
            <input
              className="form-input"
              type="text"
              name="waktu_kejadian"
              required
            />
          </div>

          <div className="form-wrapper-child">
            <label className="form-label">
              Lokasi Kejadian *Mohon isi dengan detail
            </label>
            <textarea
              className="form-input"
              type="text"
              rows="4"
              cols="50"
              name="lokasi"
              required
            />
          </div>

          <div className="form-wrapper-child-input">
            <label className="form-label">Foto Kejadian Bencana</label>
            <br></br>
            <input
              className="form-input"
              type="file"
              accept="image/png"
              required
              name="attachment"
            />
            <div
              style={{
                fontSize: 9,
                color: "red",
              }}
            >
              Pastikan format file anda .png atau .jpg (Maks 500Kb)
            </div>
            <input
              className="form-input"
              type="file"
              accept="image/png"
              // required
              name="attachment1"
            />
            <div
              style={{
                fontSize: 9,
                color: "red",
              }}
            >
              Pastikan format file anda .png atau .jpg (Maks 500Kb)
            </div>
            <input
              className="form-input"
              type="file"
              accept="image/png"
              // required
              name="attachment2"
            />
            <div
              style={{
                fontSize: 9,
                color: "red",
              }}
            >
              Pastikan format file anda .png atau .jpg (Maks 500Kb)
            </div>
            <input
              className="form-input"
              type="file"
              accept="image/png"
              // required
              name="attachmen3"
            />
            <div
              style={{
                fontSize: 9,
                color: "red",
              }}
            >
              Pastikan format file anda .png atau .jpg (Maks 500Kb)
            </div>
          </div>

          <input className="btn-send" type="submit" value="Kirim Data" />
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Form4;
